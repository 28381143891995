import { Machine, assign } from 'xstate'
import { Auth } from 'aws-amplify'
import { navigate } from '@reach/router'
import LS from '../../utils/local-storage'

const signupStates = {
  init: 'init',
  login: 'login',
  loggedIn: 'loggedIn',
  loggingOut: 'loggingOut',
}

const events = {
  SUBMIT: 'submit',
}

// SERVICES

const signup = async (ctx: any, evt: any) => {
  const { email, password } = evt.payload
  return Auth.signUp({ username: email, password, attributes: { email } })
}

// OPTIONS

const signupOpts = {
  services: {
    signup,
  },
  actions: {
    cacheError: assign((ctx, evt: any) => {
      console.log({ error: evt })

      return {
        error: evt.data?.message,
      }
    }),
    removeError: assign({ error: null }),
    cacheSignupInfo: (ctx, evt: any) => {
      const { email, password } = evt.payload
      LS.setItem('app:user', { email, password })
    },
    removeSignupInfo: () => {
      LS.removeItem('app:user')
    },
    redirectToConfirmation: () => {
      navigate('/confirmation')
    },
  },
}

const signupMachine = Machine<any>(
  {
    id: 'signup',
    initial: 'idle',
    context: {
      error: null,
    },
    states: {
      idle: {
        on: {
          [events.SUBMIT]: 'fetching',
        },
      },
      fetching: {
        entry: 'cacheSignupInfo',
        invoke: {
          id: 'auth',
          src: 'signup',
          onDone: {
            target: '#confirmation',
          },
          onError: {
            target: 'error',
            actions: ['cacheError'],
          },
        },
      },
      error: {
        on: {
          [events.SUBMIT]: 'fetching',
        },
        exit: 'removeError',
      },
      confirmation: {
        entry: 'redirectToConfirmation',
        id: 'confirmation',
        type: 'final',
      },
    },
  },
  signupOpts
)

export { signupStates, events }

export default signupMachine
