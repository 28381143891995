import React, { useEffect } from 'react'
import {
  Stack,
  Heading,
  Input,
  Button,
  FormControl,
  FormLabel,
  Flex,
  Text,
  Link,
  Spinner,
  FormErrorMessage,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useMachine } from '@xstate/react'
import { Link as GatsbyLink, navigate } from 'gatsby'
import signupMachine, { events } from './signup.machine'
import { useUserService } from '../../features/user/user.service'

const CreateAccount = () => {
  const [currentState, send] = useMachine(signupMachine)
  const { isInitialized, isAuthenticated } = useUserService()

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      navigate('/dashboard')
    }
  }, [isInitialized, isAuthenticated])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: values => {
      send({
        type: events.SUBMIT,
        payload: { email: values.email, password: values.password },
      })
    },
  })

  const isLoading = currentState.matches('fetching')

  return (
    <Stack maxWidth="md" spacing={5} width="100%">
      <Heading textAlign="center">Sign up</Heading>
      <Text textAlign="center">
        Already have an account?
        <Link
          as={GatsbyLink}
          color="blue.500"
          fontWeight="bold"
          ml="2"
          to="/login"
        >
          Sign in
        </Link>
      </Text>
      <Stack borderWidth="1px" mt={5} px={5} py={3} rounded="md">
        <FormControl
          as="form"
          isInvalid={Boolean(currentState.context.error)}
          my={6}
          onSubmit={formik.handleSubmit}
        >
          <FormLabel htmlFor="email">Email</FormLabel>
          <Input
            id="email"
            mb={4}
            onChange={formik.handleChange}
            size="lg"
            type="email"
            value={formik.values.email}
          />
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input
            id="password"
            mb={4}
            onChange={formik.handleChange}
            size="lg"
            type="password"
            value={formik.values.password}
          />

          <Flex justifyContent="flex-end" mb={4}>
            <Link as={GatsbyLink} textDecoration="underline" to="/confirmation">
              Have a confirmation code?
            </Link>
          </Flex>
          <FormErrorMessage>{currentState.context.error}</FormErrorMessage>
          <Button mt={5} type="submit" colorScheme="teal" width="100%">
            {isLoading ? <Spinner /> : 'Create my account'}
          </Button>
        </FormControl>
      </Stack>
    </Stack>
  )
}

export default CreateAccount
